import moment from 'moment';
import { cloneDate } from '~/Utils/Helpers';

const INITIAL_STATE = {};

const financiamentos = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@vendas/ADICIONA_PARCELA_FINANCIAMENTO': {
      const {
        codigo,
        valorPresente,
        valor,
        vencimento,
        tipo,
        parcelas,
        numeroDaParcela = 0,
        tipoParcelaEscadinha = false,
        tipoBalao = null,
        taxa = null,
        tipoIntermediaria = false,
      } = action.payload;
      let ativo = state[codigo] || {};
      let financiamento = ativo.parcelasFinanciamento || [];

      if (tipoBalao !== null) {
        financiamento.push({
          valor_presente: valorPresente,
          valor,
          vencimento,
          parcela: 1,
          periodicidade_balao: tipoBalao,
          codigo_intermediaria: tipo,
          taxa: taxa,
          tipo_parcela_financiamento: 'Condicionante',
        });
      } else if (tipoParcelaEscadinha) {
        financiamento.push({
          valor_presente: valorPresente,
          valor,
          vencimento: vencimento,
          parcela: numeroDaParcela,
          tipo_escadinha: true,
          tipo_parcela: tipo,
          periodicidade_balao: null,
          tipo_parcela_financiamento: 'Programada',
        });
      } else if (tipoIntermediaria) {
        financiamento.push({
          valor_presente: valorPresente,
          valor,
          vencimento: moment(vencimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          parcela: numeroDaParcela,
          tipo_parcela: tipo,
          tipo_intermediaria: true,
          tipo_parcela_financiamento: 'Intermediária',
        });
      } else {
        for (let i = 1; i <= parcelas; i++) {
          financiamento.push({
            valor_presente: valorPresente,
            valor,
            vencimento: cloneDate(vencimento)
              .add(i - 1, 'months')
              .format('YYYY-MM-DD'),
            parcela: i,
            tipo_parcela: tipo,
            periodicidade_balao: null,
            tipo_parcela_financiamento: 'Financiamento',
          });
        }
      }

      const financiamentoOrdenado = financiamento.sort(
        (a, b) => new Date(a.vencimento) - new Date(b.vencimento)
      );

      const quantidadeParcelas = financiamento.filter(
        p => !p.periodicidade_balao && !p.tipo_escadinha & !p.tipo_intermediaria
      );

      return {
        ...state,
        [codigo]: {
          ...state[codigo],
          parcelasFinanciamento: financiamentoOrdenado,
          shortForm: {
            quantidade_parcelas: quantidadeParcelas.length,
            valor_presente: valorPresente,
            valor: valor,
            vencimento: vencimento,
          },
        },
      };
    }
    case '@vendas/REMOVE_TODAS_PARCELAS_FINANCIAMENTO': {
      const { codigo } = action.payload;
      return {
        ...state,
        [codigo]: {
          ...state[codigo],
          parcelasFinanciamento: [],
          shortForm: {
            quantidade_parcelas: undefined,
            valor_presente: undefined,
            valor: undefined,
            vencimento: undefined,
          },
        },
      };
    }

    case '@vendas/ADICIONA_FORMA_PAGAMENTO_ENTRADA':
    case '@vendas/REMOVE_FORMA_PAGAMENTO_ENTRADA':
    case '@vendas/ATUALIZA_FORMA_PAGAMENTO_ENTRADA':
    case '@vendas/REMOVE_TOTAS_PARCELAS_ENTRADA':
    case '@vendas/SELECIONA_TABELA_PRECO':
    case '@vendas/SELECIONA_ATIVO':
    case '@vendas/SALVAR_SINAL':
      return {};

    // case '@vendas/REMOVE_PARCELA_FINANCIAMENTO': {
    //   const { codigo, parcela } = action.payload;
    //   console.log(action.payload);
    //   return {
    //     ...state,
    //     [codigo]: {
    //       ...state[codigo],
    //       financiamento: state[codigo].financiamento.filter(
    //         parc => parc.parcela !== parcela
    //       ),
    //     },
    //   };
    // }
    // case '@vendas/ATUALIZA_PARCELA_FINANCIAMENTO': {
    //   const { codigo, parcela, valor, vencimento } = action.payload;
    //   return {
    //     ...state,
    //     [codigo]: {
    //       ...state[codigo],
    //       financiamento: state[codigo].financiamento.map(parc =>
    //         parc.parcela !== parcela ? parc : { ...parc, valor, vencimento }
    //       ),
    //     },
    //   };
    // }

    default:
      return state;
  }
};

export default financiamentos;
