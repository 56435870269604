// import mockGetProposta from '~/Pages/Vendas/mockGetProposta.json';
import * as api from '~/Services/Service';
import { BOLETO, CHEQUE, CARTAO } from '~/constant';

import { salvarSinal, adicionaFormaPgtoEntrada } from './Sinal/actions';
import { adicionarParcelaFinanciamento } from './Financiamento/actions';
import { setDataVenda, setCodigoPontoVenda } from './Checkout/actions';
import { dividirComissao } from './DivisaoComissao/actions';
import { createDate } from '~/Utils/Helpers';

export const selecionaAtivo = codigoAtivo => ({
  type: '@vendas/SELECIONA_ATIVO',
  payload: {
    codigoAtivo,
  },
});

export const selecionaTabelaPreco = codigoTabela => (dispatch, getState) => {
  const tabela = getState().vendas.tabelasPorCodigo[codigoTabela];
  dispatch(setTabelaPreco(tabela));
};

export const setTabelaPreco = tabela => ({
  type: '@vendas/SELECIONA_TABELA_PRECO',
  payload: {
    codigoTabela: tabela.codigo,
    ativos: tabela.ativos,
  },
});

export const selecionaConstrutor = codigo => ({
  type: '@vendas/SELECIONA_CONSTRUTOR',
  payload: {
    codigoConstrutor: codigo,
  },
});

export const setConstrutor = codigo => ({});

export const carregarPropostaInicial = (
  codigoFamilia,
  idProspecto // criptografado
) => async dispatch => {
  dispatch(carregandoProposta());

  try {
    const propostaInicial = await api.obterPropostaInicial(
      codigoFamilia,
      idProspecto
    );
    dispatch(setPropostaInicial(propostaInicial));
  } catch (err) {
    console.error(err);
    dispatch(erroCarregarProposta(err.message || 'Proposta não encontrada'));
  }
};

const setPropostaInicial = proposta => ({
  type: '@vendas/PROPOSTA_CARREGADA',
  payload: {
    proposta: proposta,
  },
});

const carregandoProposta = () => ({
  type: '@vendas/CARREGANDO_PROPOSTA',
});

const erroCarregarProposta = message => ({
  type: '@vendas/ERRO_CARREGAR_PROPOSTA',
  payload: message,
});

export const carregarPropostaAuxiliar = codigoProposta => async dispatch => {
  dispatch(carregandoProposta());

  try {
    const response = await api.buscarPropostaAuxiliar(codigoProposta);
    dispatch(setPropostaInicial(response.proposta_inicial));

    const gravada = response.proposta_gravada;

    // selecionar tabela
    const tabela = response.proposta_inicial.empreendimento.tabelas.find(
      tab => tab.codigo === gravada.tabela_selecionada
    );
    dispatch(setTabelaPreco(tabela));
    // adicionar ativo e configurar os valores
    for (const ativo of gravada.ativos) {
      const { codigo, valor_sinal, valor_venda, desconto } = ativo;

      const {
        sinal: desconto_sinal,
        valor_venda: desconto_valor_venda,
        aplicou_cheque_bonus_sinal,
        aplicou_cheque_bonus_valor_venda,
        codigo_solicitacao_desconto,
      } = desconto || {};
      dispatch(selecionaAtivo(codigo));
      dispatch(
        salvarSinal({
          codigo,
          valor_sinal,
          valor_venda,
          desconto_sinal,
          desconto_valor_venda,
          aplicou_cheque_bonus_sinal,
          aplicou_cheque_bonus_valor_venda,
          codigo_solicitacao_desconto,
        })
      );
    }

    // adicionar parcelas sinal
    for (const parcela of gravada.sinal) {
      dispatch(
        adicionaFormaPgtoEntrada({
          ...parcela,
          qtdParcelas: 1,
          vencimento: createDate(parcela.vencimento),
        })
      );
    }

    // adicionar parcelas de financiamento
    for (const ativo of gravada.ativos) {
      const {
        valorPresente,
        valor,
        vencimento,
        quantidade_parcelas,
      } = ativo.financiamento;

      //AQUI PEGA AS CONDICIONANTES E ADICIONA NO OBJETO FINAL
      ativo.parcelas_condicionantes.forEach(item => {
        dispatch(
          adicionarParcelaFinanciamento(
            ativo.codigo,
            item.valor_presente,
            item.valor,
            item.vencimento,
            item.codigo_intermediaria,
            1,
            1, //numeroDaParcela
            false, //tipoParcelaEscadinha
            item.periodicidade_balao //tipoBalao
          )
        );
      });

      //AQUI PEGA AS INTERMEDIARIAS E ADICIONA NO OBJETO FINAL
      ativo.parcelas_intermediarias.forEach((item, index) => {
        dispatch(
          adicionarParcelaFinanciamento(
            item.codigo_ativo, //codigoAtivo
            item.valor_presente, //valorPresente
            item.valor_futuro, //valor
            item.vencimento, // vencimento
            BOLETO, //tipo
            1, //parcelas
            index + 1, //numeroDaParcela
            null, //tipoParcelaEscadinha
            null, //tipoBalao
            null, //taxa
            true // tipoIntermediaria
          )
        );
      });

      if (ativo.parcelas_em_series) {
        var numeroDaParcela = 1;
        //Parcelas em séries
        ativo.parcelas_em_series.forEach(item => {
          dispatch(
            adicionarParcelaFinanciamento(
              ativo.codigo,
              item.valor_presente,
              item.valor,
              item.vencimento,
              BOLETO,
              1,
              numeroDaParcela,
              true //tipoParcelaEscadinha
            )
          );
          numeroDaParcela++;
        });
      }

      dispatch(
        adicionarParcelaFinanciamento(
          ativo.codigo,
          valorPresente,
          valor,
          vencimento,
          BOLETO,
          quantidade_parcelas
        )
      );

      if (ativo.divisao_comissao) {
        dispatch(
          dividirComissao({
            ...ativo.divisao_comissao,
            codigo: ativo.codigo,
          })
        );
      }
    }

    dispatch(setDataVenda(createDate(gravada.data)));
    dispatch(setCodigoPontoVenda(gravada.codigo_ponto_venda));
  } catch (err) {
    console.error(err);
    dispatch(erroCarregarProposta(err.message || 'Proposta não encontrada'));
  }
};

export const setProspectoVenda = () => (dispatch, getState) => {
  const prospecto = getState().prospecto.detail;

  dispatch({
    type: '@vendas/SET_PROSPECTO',
    payload: {
      prospecto,
    },
  });
};

export const setCondicoesEspeciais = novasCondicoes => async dispatch => {
  dispatch({
    type: '@vendas/SET_CONDICOES_ESPECIAIS',
    payload: {
      condicoesEspeciais: novasCondicoes,
    },
  });
};

export const setVendaInterna = isVendaInterna => async dispatch => {
  dispatch({
    type: '@vendas/SET_VENDA_INTERNA',
    payload: {
      vendaInterna: isVendaInterna,
    },
  });
};

export const gravarVenda = checkoutData => async (dispatch, getState) => {
  dispatch({
    type: '@vendas/GRAVANDO_VENDA',
  });
  const state = getState().vendas;
  const codigoProspecto = state.prospecto.codigo;
  const codigoFamilia = state.empreendimento.codigo;
  const ativos = Object.values(state.ativosDaTabelaSelecionadaPorCodigo)
    .filter(ativo => state.ativosSelecionados.includes(ativo.codigo))
    .map(ativo => ({
      ...ativo,
      financiamento: state.financiamentos[ativo.codigo].shortForm,
      parcelas_intermediarias: state.financiamentos[
        ativo.codigo
      ].parcelasFinanciamento.filter(x => x.tipo_intermediaria),
      parcelas_em_series: state.financiamentos[
        ativo.codigo
      ].parcelasFinanciamento.filter(x => x.tipo_escadinha),
      parcelas_condicionantes: state.financiamentos[
        ativo.codigo
      ].parcelasFinanciamento.filter(x => x.periodicidade_balao),
      divisao_comissao: state.divisaoComissao[ativo.codigo],
    }));

  const autorizacao = {
    justificativa: checkoutData.justificativa,
    codigo_usuario: checkoutData.codigoUsuarioAutorizacao,
    senha: checkoutData.senha || checkoutData.contraSenha,
  };

  const sinal = state.sinal.listaFormasDePagamento.map(sinal => {
    const { tipo_parcela, vencimento, valor } = sinal;
    if (tipo_parcela === CHEQUE)
      return {
        vencimento,
        tipo_parcela,
        valor,
        cheque: sinal,
      };
    else if (sinal.tipo_parcela === CARTAO) {
      const { cartao, numero_documento } = sinal;
      return {
        vencimento,
        tipo_parcela,
        valor,
        cartao: {
          codigo: cartao,
          numero_documento,
        },
      };
    } else
      return {
        vencimento,
        tipo_parcela,
        valor,
      };
  });

  let request = {
    codigo_familia: codigoFamilia,
    tabela_selecionada: state.tabelaSelecionada,
    autorizacao: autorizacao,
    ativos: ativos,
    sinal: sinal,
    data: state.checkout.dataVenda,
    codigo_ponto_venda: checkoutData.codigoPontoVenda,
    codigo_proposta: null,
    codigo_construtor: state.construtorSelecionado,
    venda_interna: checkoutData.vendaInterna,
    contrato_digital: checkoutData.contratoDigital,
  };
  try {
    const response = await api.gravarVenda(
      codigoFamilia,
      codigoProspecto,
      request
    );
    dispatch({
      type: '@vendas/GRAVAR_VENDA_SUCESSO',
      payload: response,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: '@vendas/GRAVAR_VENDA_ERRO',
      payload: err.message,
    });
  }
};

export const autorizarVenda = (
  codigoProposta,
  justificativa
) => async dispatch => {
  dispatch({
    type: '@vendas/GRAVANDO_VENDA',
  });

  try {
    const autorizacao = {
      justificativa,
    };
    const response = await api.autorizarVenda(codigoProposta, autorizacao);
    dispatch({
      type: '@vendas/GRAVAR_VENDA_SUCESSO',
      payload: response,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: '@vendas/GRAVAR_VENDA_ERRO',
      payload: err.message,
    });
  }
};

export const excluirVenda = codigoProposta => async dispatch => {
  dispatch({
    type: '@vendas/EXCLUINDO_VENDA',
  });

  try {
    const response = await api.excluirPropostaRapidaAuxiliar(codigoProposta);

    dispatch({
      type: '@vendas/EXCLUIR_VENDA_SUCESSO',
      payload: response,
    });
  } catch (err) {
    console.error(err);
    dispatch({
      type: '@vendas/EXCLUIR_VENDA_ERRO',
      payload: err.message,
    });
  }
};
