import { Button, Col, Modal, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import * as api from '~/Services/Service';
import { formatarMonetario } from '~/Utils/Helpers';
import { toast } from 'react-toastify';

const ModalReserva = ({
  mostrarModal,
  setMostrarModal,
  atualizarReservados,
  atualizarMapa,
  ativo,
}) => {
  const [dadosAtivo, setDadosAtivo] = useState({
    quadra: '',
    lote: '',
    valor_vista: 0,
    valor_sinal: 0,
    area: 0,
    parcelas: 0,
    valor_parcela: 0,
    nome_corretor: '',
  });

  useEffect(() => {
    setDadosAtivo(ativo);
  }, [mostrarModal]);

  useEffect(() => {
    if (mostrarModal == false && atualizarReservados != undefined) {
      atualizarReservados();
      atualizarMapa();
    }
  }, [mostrarModal]);

  const handleClick = async () => {
    const objResponse = {
      codigo_ativo: ativo.codigo,
    };
    try {
      const response = await api.reservarLote(objResponse);
      toast.success(response.message);
      const loteElement = document.getElementById(ativo.codigo);
      loteElement.classList.add('reservado');
      loteElement.classList.remove('disponivelF1', 'disponivelF2');
    } catch (error) {
      toast.error(error.message);
    }
    setMostrarModal(false);
  };
  const handleClickEstornar = async () => {
    try {
      const response = await api.estornarLote(ativo.codigo);
      toast.success(response.message);
      setMostrarModal(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const {
    quadra,
    lote,
    valor_vista,
    valor_sinal,
    area,
    parcelas,
    valor_parcela,
    nome_corretor,
    nome_cliente,
    valor_inicial_parcela_programada,
    solicitacao_estorno,
    codigo_mesa,
    data_validade,
    data_reserva,
    status,
    codigo_familia,
    codigo,
  } = dadosAtivo;
  const reservado = !!nome_corretor && status == 5;
  const fase = codigo_familia == 11452 ? '1' : '2';
  return (
    <Modal show={mostrarModal} onHide={() => setMostrarModal(false)} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Informações do lote</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="d-flex justify-content-center mb-1">
          <Col className="text-center">
            {nome_cliente && status != 0 ? (
              <>
                <div
                  style={{
                    backgroundColor: '#FFD580',
                    borderRadius: '4px',
                    padding: '4px 8px',
                  }}
                >
                  {nome_cliente}
                </div>
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className="d-flex flex-column mt-1">
              <Col>
                <small>
                  Fase: <strong>{fase}</strong> / Ativo:{' '}
                  <strong>{codigo}</strong>
                </small>
              </Col>
              <Col>
                <small>
                  Área: <strong>{area}m²</strong>
                </small>
              </Col>
              <Col>
                <small>
                  Valor à vista:{' '}
                  <strong>{formatarMonetario(valor_vista)}</strong>
                </small>
              </Col>
              <Col>
                <small>
                  Entrada: <strong>{formatarMonetario(valor_sinal)}</strong>
                </small>
              </Col>

              {/* {codigo_mesa ? (
                <>
                  <Col>
                    <small>
                      Mesa:
                      <strong> {codigo_mesa}</strong>
                    </small>
                  </Col>
                </>
              ) : (
                <></>
              )} */}
            </Row>
          </Col>
          <Col className="d-flex flex-column align-items-end">
            <Row>
              <Col>
                <small>
                  Quadra: <strong>{quadra} </strong> / Lote:{' '}
                  <strong>{lote}</strong>
                </small>
              </Col>
            </Row>
            <Row>
              {data_validade ? (
                <>
                  <Col>
                    <small>
                      Validade:
                      <strong>
                        {' '}
                        {`${new Date(data_validade)
                          .getDate()
                          .toString()
                          .padStart(2, '0')}/${(
                          new Date(data_validade).getMonth() + 1
                        )
                          .toString()
                          .padStart(2, '0')} ${new Date(data_validade)
                          .getHours()
                          .toString()
                          .padStart(2, '0')}:${new Date(data_validade)
                          .getMinutes()
                          .toString()
                          .padStart(2, '0')}`}
                      </strong>
                    </small>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
            <Row>
              {data_reserva ? (
                <>
                  <Col>
                    <small>
                      Reserva:{' '}
                      <strong>{`${new Date(data_reserva)
                        .getDate()
                        .toString()
                        .padStart(2, '0')}/${(
                        new Date(data_reserva).getMonth() + 1
                      )
                        .toString()
                        .padStart(2, '0')} ${new Date(data_reserva)
                        .getHours()
                        .toString()
                        .padStart(2, '0')}:${new Date(data_reserva)
                        .getMinutes()
                        .toString()
                        .padStart(2, '0')}`}</strong>
                    </small>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
            <Row>
              <Col>
                <small>
                  Parcelamento:{' '}
                  <strong>
                    {parcelas} x {formatarMonetario(valor_parcela)}
                  </strong>
                </small>
              </Col>
            </Row>
            {valor_inicial_parcela_programada ? (
              <>
                <Row>
                  <Col>
                    <small>
                      Programadas:{' '}
                      <strong>
                        {formatarMonetario(valor_inicial_parcela_programada)}
                      </strong>
                    </small>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}

            <Row>
              <Col>
                <small>
                  {nome_corretor ? (
                    <>
                      Corretor: <strong>{nome_corretor}</strong>
                    </>
                  ) : (
                    <>&nbsp;</>
                  )}
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer
        style={{
          justifyContent:
            solicitacao_estorno ? 'space-between' : 'right',
        }}
      >
        { solicitacao_estorno ? (
          <>
            <Button
              variant={'info'}
              disabled={!solicitacao_estorno}
              onClick={handleClickEstornar}
            >
              Estornar lote
            </Button>
          </>
        ) : (
          <></>
        )}
        <Button
          disabled={status != 0}
          variant={status != 0 ? 'secondary' : 'primary'}
          onClick={handleClick}
        >
          Reservar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalReserva;
