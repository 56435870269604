import React, { useEffect, useState } from 'react';
import { Button, Table, Modal } from 'react-bootstrap';
import { FaSearch, FaTrash } from 'react-icons/fa';
import ModalReserva from './ModalReserva';
import * as api from '~/Services/Service';
import { toast } from 'react-toastify';

const LotesVendas = ({
  ativosReservados,
  atualizarReservados,
  atualizarMapa,
  ativos,
}) => {
  const [mostrarModal, setMostrarModal] = useState(null);
  const [mostrarModalConfirmacao, setMostrarModalConfirmacao] = useState(false);
  const [ativoSelecionado, setAtivoSelecionado] = useState(false);
  const [prospectoAtual, setProspectoAtual] = useState(false);

  useEffect(() => {
    const prospecto = ativosReservados.find(
      reserva => reserva.codigo_prospecto != null
    );
    setProspectoAtual(prospecto);
  }, [ativosReservados]);

  const handleClickVenda1 = () => {
    window.location.href = `/vendas/prospecto/${prospectoAtual.codigo_prospecto}/empreendimento/11452`;
  };

  const handleClickVenda2 = () => {
    window.location.href = `/vendas/prospecto/${prospectoAtual.codigo_prospecto}/empreendimento/11453`;
  };

  const handleDelete = async () => {
    try {
      const response = await api.excluirReserva(ativoSelecionado.codigo);
      toast.success(response.message);
      atualizarMapa();
      atualizarReservados();
      setMostrarModalConfirmacao(false);
    } catch (error) {
      toast.error(error);
      setMostrarModalConfirmacao(false);
    }
  };

  const confirmarExclusao = id => {
    atualizarMapa();
    const ativo = ativos.find(imovel => imovel.codigo === id);
    setAtivoSelecionado(ativo);
    setMostrarModalConfirmacao(true);
  };

  const pesquisarAtivo = id => {
    atualizarMapa();
    console.log('passou no pesquisarAtivo, ativo: ', id)
    const ativo = ativos.find(a => a.codigo === id);
    setAtivoSelecionado(ativo);
    setMostrarModal(true);
  };

  const temFamilia1 =
    ativosReservados &&
    ativosReservados.some(reserva => reserva.codigo_empreendimento === 11452);
  const temFamilia2 =
    ativosReservados &&
    ativosReservados.some(reserva => reserva.codigo_empreendimento === 11453);

  const tableStyle =
    !!ativosReservados && ativosReservados.length > 3
      ? { maxHeight: '300px', overflowY: 'auto' }
      : { maxHeight: 'auto', overflowY: 'hidden' };

  return (
    <>
      <div>
        <div
          style={{
            borderRadius: '7px',
            overflowY: 'auto',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
            ...tableStyle,
          }}
        >
          <Table striped bordered hover className="mb-0 table-sm text-center">
            <thead
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: temFamilia1
                  ? '#4E80B7'
                  : temFamilia2
                    ? '#EE7219'
                    : '#FFFFFF',
                zIndex: 1,
                color: 'white',
              }}
            >
              <tr>
                <th>QD</th>
                <th>LT</th>
                <th>Prosp.</th>
                <th>Validade</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              {ativosReservados.length > 0 ? (
                ativosReservados.map((reserva, index) => (
                  <tr
                    key={index}
                    style={{
                      color: 'white',
                      background:
                        reserva.codigo_empreendimento === 11452
                          ? '#4E80B7'
                          : reserva.codigo_empreendimento === 11453
                            ? '#EE7219'
                            : '#FFFFFF',
                    }}
                  >
                    <td>{reserva.mapa1}</td>
                    <td>{reserva.mapa2}</td>
                    <td>{reserva.codigo_prospecto}</td>
                    <td>
                      {`${new Date(reserva.data_vencimento)
                        .getDate()
                        .toString()
                        .padStart(2, '0')}/${(
                          new Date(reserva.data_vencimento).getMonth() + 1
                        )
                          .toString()
                          .padStart(2, '0')} ${new Date(reserva.data_vencimento)
                            .getHours()
                            .toString()
                            .padStart(2, '0')}:${new Date(reserva.data_vencimento)
                              .getMinutes()
                              .toString()
                              .padStart(2, '0')}`}
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        className="mr-1 mb-1"
                        onClick={() => confirmarExclusao(reserva.codigo_ativo)}
                      >
                        <FaTrash />
                      </Button>
                      <Button
                        variant="primary"
                        size="sm"
                        className="ml-1 mb-1"
                        onClick={() => pesquisarAtivo(reserva.codigo_ativo)}
                      >
                        <FaSearch />
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    Sem lotes reservados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <div
          style={{
            display: 'flex',
            padding: '2px',
            justifyContent:
              temFamilia1 && temFamilia2 ? 'space-between' : 'right',
            marginTop: '7px',
          }}
        >
          {temFamilia1 && (
            <Button
              style={{ backgroundColor: '#4E80B7', color: 'white' }}
              onClick={handleClickVenda1}
              variant="light"
            >
              Iniciar venda fase 1
            </Button>
          )}
          {temFamilia2 && (
            <Button
              style={{ backgroundColor: '#EE7219', color: 'white' }}
              onClick={handleClickVenda2}
              variant="light"
            >
              Iniciar venda fase 2
            </Button>
          )}
        </div>

        {ativoSelecionado && (
          <Modal
            show={mostrarModalConfirmacao}
            onHide={() => setMostrarModalConfirmacao(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Cancelamento da reserva</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Tem certeza de que deseja excluir a reserva?</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between'
                }}
              >
                <small>
                  Id: <strong>{ativoSelecionado.codigo}</strong>
                </small>
                <small>
                  Fase:{' '}
                  <strong>
                    {ativoSelecionado.codigo_familia === 11452 ? '1' : '2'}
                  </strong>{' '}
                </small>
                <small>
                  Quadra: <strong>{ativoSelecionado.quadra}</strong>
                </small>
                <small>
                  Lote: <strong>{ativoSelecionado.lote}</strong>
                </small>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  flexWrap: 'wrap',
                  marginTop: '8px',
                  justifyContent: 'space-between'
                }}
              >
                <small>
                  Reservado:{' '}
                  <strong>
                    {`${new Date(ativoSelecionado.data_reserva)
                      .getDate()
                      .toString()
                      .padStart(2, '0')}/${(
                        new Date(ativoSelecionado.data_reserva).getMonth() + 1
                      )
                        .toString()
                        .padStart(2, '0')} ${new Date(
                          ativoSelecionado.data_reserva
                        )
                          .getHours()
                          .toString()
                          .padStart(2, '0')}:${new Date(
                            ativoSelecionado.data_reserva
                          )
                            .getMinutes()
                            .toString()
                            .padStart(2, '0')}`}
                  </strong>
                </small>
                <small>
                  Vencimento:{' '}
                  <strong>{`${new Date(ativoSelecionado.data_validade)
                    .getDate()
                    .toString()
                    .padStart(2, '0')}/${(
                      new Date(ativoSelecionado.data_validade).getMonth() + 1
                    )
                      .toString()
                      .padStart(2, '0')} ${new Date(
                        ativoSelecionado.data_validade
                      )
                        .getHours()
                        .toString()
                        .padStart(2, '0')}:${new Date(
                          ativoSelecionado.data_validade
                        )
                          .getMinutes()
                          .toString()
                          .padStart(2, '0')}`}</strong>
                </small>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setMostrarModalConfirmacao(false)}
              >
                Não
              </Button>
              <Button variant="danger" onClick={handleDelete}>
                Sim
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {ativoSelecionado && (
          <ModalReserva
            mostrarModal={mostrarModal}
            setMostrarModal={setMostrarModal}
            ativo={ativoSelecionado}
            atualizarReservados={atualizarReservados}
            atualizarMapa={atualizarMapa}
          />
        )}
      </div>
    </>
  );
};

export default LotesVendas;
