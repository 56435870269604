import MapaInterativo from '~/Components/Mapa/MapaInterativo';
import React, { useCallback, useEffect, useState } from 'react';
import * as api from '~/Services/Service';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import LotesVendas from '~/Components/Mapa/LotesVendas';
import { toast } from 'react-toastify';
import Loading from '~/Components/Loading';
import { StatusImovel } from '~/constant';

const mapaHumanizado = () => {
  const [ativos, setAtivos] = useState(null);
  const [ativosReservadosF1, setAtivosReservadosF1] = useState(null);
  const [ativosReservadosF2, setAtivosReservadosF2] = useState(null);
  const [informacoesDizeres, setInformacoesDizeres] = useState({
    fase1Disponiveis: 0,
    fase2Disponiveis: 0,
    bloqueados: 0,
    vendidos: 0,
    reservados: 0,
    preVendas: 0,
  });
  const [loading, setLoading] = useState(true);

  const fetchFamilias = useCallback(async () => {
    try {
      const ativos = await api.buscarFamiliaLancamento();
      setAtivos(ativos);
    } catch (error) {
      toast.error(error.message || 'Erro ao buscar famílias');
    }
  }, []);

  const fetchLotesReservados = useCallback(async () => {
    try {
      const ativosReservadosF1 = await api.listarLotesReservados(11452);
      const ativosReservadosF2 = await api.listarLotesReservados(11453);

      setAtivosReservadosF1(ativosReservadosF1);
      setAtivosReservadosF2(ativosReservadosF2);
    } catch (error) {
      console.error('Erro ao buscar lotes reservados:', error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchFamilias(), fetchLotesReservados()]);
      setLoading(false);
    };
    fetchData();
  }, [fetchFamilias, fetchLotesReservados]);

  useEffect(() => {
    if (ativos) {
      const countLotes = ativos.reduce(
        (acc, ativo) => {
          switch (ativo.status) {
            case StatusImovel.Disponivel:
              if (ativo.codigo_familia == 11452) {
                acc.fase1Disponiveis += 1;
              } else if (ativo.codigo_familia == 11453) {
                acc.fase2Disponiveis += 1;
              }
              break;
            case StatusImovel.Bloqueado:
              acc.bloqueados += 1;
              break;
            case StatusImovel.Vendido:
              acc.vendidos += 1;
              break;
            case StatusImovel.PreVenda:
              acc.vendidos += 1;
              break;
            case StatusImovel.Reservado:
              acc.reservados += 1;
              break;
            default:
              break;
          }
          return acc;
        },
        {
          fase1Disponiveis: 0,
          fase2Disponiveis: 0,
          bloqueados: 0,
          vendidos: 0,
          reservados: 0,
        }
      );

      setInformacoesDizeres(countLotes);
    }
  }, [ativos]);

  return (
    <>
      {loading ? (
        <div className="mx-auto mt-4">
          <Loading label="Carregando mapa..." />
        </div>
      ) : (
        <Container fluid className="w-100 h-100 p-0 mt-3">
          <Row className="mr-0" style={{ background: '#353535' }}>
            <Col md={9}>
              <MapaInterativo
                ativos={ativos}
                atualizarMapa={fetchFamilias}
                atualizarReservados={fetchLotesReservados}
              />
              <div
                style={{
                  position: 'fixed',
                  right: '30%',
                  bottom: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px',
                  zIndex: 1000,
                }}
              >
                <div className='contadores-mapa'
                  style={{ backgroundColor: '#4E80B7', color: 'white' }}
                >Qtd. Fase 1: {informacoesDizeres.fase1Disponiveis}</div>
                <div className='contadores-mapa'
                  style={{ backgroundColor: '#EE7219', color: 'white' }}
                >Qtd. Fase 2: {informacoesDizeres.fase2Disponiveis}</div>
                <div className='contadores-mapa'
                  style={{ backgroundColor: '#0847A6', color: 'white' }}
                >Qtd. Vendidos: {informacoesDizeres.vendidos}</div>
                <div className='contadores-mapa'
                  style={{ backgroundColor: '#FFDC15' }}
                >Qtd. Reservados: {informacoesDizeres.reservados}</div>
                <div className='contadores-mapa'
                  style={{ backgroundColor: 'black', color: 'white' }}
                >Qtd. Bloqueados: {informacoesDizeres.bloqueados}</div>
                <div className='contadores-mapa'
                  style={{ backgroundColor: 'grey', color: 'white' }}
                >Qtd. Total: {ativos.length}</div>
              </div>
            </Col>
            <Col md={3} className="mt-3">
              <div
                className="d-flex flex-column justify-content-between"
                style={{
                  right: '2%',
                  top: '100px',
                  position: 'fixed',
                  height: '55%',
                  width: '21%',
                }}
              >
                {ativosReservadosF1.length !== 0 && (
                  <LotesVendas
                    ativosReservados={ativosReservadosF1}
                    atualizarReservados={fetchLotesReservados}
                    atualizarMapa={fetchFamilias}
                    ativos={ativos}
                  />
                )}
                {ativosReservadosF2.length !== 0 && (
                  <LotesVendas
                    ativosReservados={ativosReservadosF2}
                    atualizarReservados={fetchLotesReservados}
                    atualizarMapa={fetchFamilias}
                    ativos={ativos}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default mapaHumanizado;
