import React, { useState, useEffect } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import moment from 'moment';
import Selector from 'react-select';
import MaskedInput from 'react-text-mask';
import { Row, Col, Form, Button } from 'react-bootstrap';
import MoneyField from '~/Components/Form/MoneyField';
import { MASK_DATE, recorrenciaDuracoes } from '~/constant';
import { formatarMonetario } from '~/Utils/Helpers';
import {
  calcularValorFuturo,
  calcularValorPresente,
} from '~/Pages/Vendas/helpers';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

const ParcelasIntermediarias = ({
  codigo,
  diaVencimento,
  taxaTabela,
  dataVenda,
  valoresCalculados,
  setValoresCalculados,
  incluirParcelas,
  saldoFinal,
}) => {
  const [tipoIntermediaria, setTipoIntermediaria] = useState('anual');
  const [valorVFVP, setValorVFVP] = useState('vf');
  const { setFieldValue, values } = useFormikContext();
  const [isDisabled, setIsDisabled] = useState(false);
  const handleRecorrenciaIntermediarias = option => {
    setTipoIntermediaria(option.value);
    const mesesParaAdicionar = recorrenciaDuracoes[option.value] || 1;
    const suggestedDate = moment()
      .add(mesesParaAdicionar, 'months')
      .set('date', diaVencimento)
      .format('DD/MM/YYYY');

    setFieldValue('vencimento_intermediarias', suggestedDate);
    if (option.value !== 'avulsa') setValorVFVP('vf');
  };

  const handleCalcular = () => {
    let novosValores = [];
    const parcelas =
      tipoIntermediaria === 'avulsa' ? 1 : Number(values.parcelas);
    const valor = values.valor;

    for (let i = 0; i < parcelas; i++) {
      let vencimentoDate;

      if (i === 0) {
        // Primeira parcela mantém a data original
        vencimentoDate = moment(
          values.vencimento_intermediarias,
          'DD/MM/YYYY'
        ).toDate();
      } else {
        // Parcelas seguintes adicionam meses
        const mesesParaAdicionar =
          i * (recorrenciaDuracoes[tipoIntermediaria] || 1);

        vencimentoDate = moment(values.vencimento_intermediarias, 'DD/MM/YYYY')
          .add(mesesParaAdicionar, 'months')
          .toDate();
      }
      let valorDescontado = valor;
      let valorProjetado = 0.0;
      if (valorVFVP === 'vf') {
        valorDescontado = calcularValorPresente(
          valor,
          taxaTabela,
          moment(dataVenda).toDate(),
          vencimentoDate
        );

        valorProjetado += valor;
      } else {
        valorProjetado = calcularValorFuturo(
          valorDescontado,
          taxaTabela,
          moment(dataVenda).toDate(),
          vencimentoDate
        );
      }
      novosValores.push({
        codigo_ativo: codigo,
        valor_presente: valorDescontado,
        valor_futuro: valorProjetado,
        vencimento: moment(vencimentoDate).format('DD/MM/YYYY'),
      });
    }

    setValoresCalculados(novosValores);
    const valoresDasParcelasIntermediarias = novosValores.reduce(
      (acc, curr) => acc + curr.valor_presente,
      0
    );
    setIsDisabled(valoresDasParcelasIntermediarias >= saldoFinal);
  };

  const isFormValid = () => {
    const vencimentoValido =
      values.vencimento_intermediarias &&
      values.vencimento_intermediarias.trim() !== '';
    const parcelasValido = tipoIntermediaria === 'avulsa' || values.parcelas;
    return values.valor && vencimentoValido && parcelasValido;
  };

  const totalValorPresente = valoresCalculados.reduce(
    (acc, curr) => acc + curr.valor_presente,
    0
  );

  const totalValorFuturo = valoresCalculados.reduce(
    (acc, curr) => acc + curr.valor_futuro,
    0
  );

  useEffect(() => {
    const mesesParaAdicionar = 12;
    const suggestedDate = moment()
      .add(mesesParaAdicionar, 'months')
      .set('date', diaVencimento)
      .format('DD/MM/YYYY');
    setFieldValue('vencimento_intermediarias', suggestedDate);
  }, [setFieldValue, diaVencimento]);

  return (
    <>
      <Row className="mt-1">
        <Col md="4">
          <small>Recorrência</small>
          <Selector
            placeholder="Intermediárias..."
            className="mb-2"
            options={[
              { value: 'anual', label: 'Anual' },
              { value: 'semestral', label: 'Semestral' },
              { value: 'quadrimestral', label: 'Quadrimestral' },
              { value: 'trimestral', label: 'Trimestral' },
              { value: 'avulsa', label: 'Avulsa' },
            ]}
            defaultValue={{ value: 'anual', label: 'Anual' }}
            onChange={handleRecorrenciaIntermediarias}
          />
        </Col>

        {tipoIntermediaria === 'avulsa' ? (
          <Col md="2">
            <small>VF/VP</small>
            <Selector
              className="mb-2"
              options={[
                { value: 'vf', label: 'VF' },
                { value: 'vp', label: 'VP' },
              ]}
              defaultValue={{ value: 'vf', label: 'VF' }}
              onChange={option => setValorVFVP(option.value)}
            />
          </Col>
        ) : (
          <Col md="2">
            <Form.Group controlId="formaPagamento">
              <small>Parcelas</small>
              <Field
                name="parcelas"
                type="number"
                className="form-control"
                min="1"
              />
              <ErrorMessage
                name="parcelas"
                className="text-danger"
                component="small"
              />
            </Form.Group>
          </Col>
        )}

        <Col md="3">
          <Form.Group controlId="formaPagamento">
            <small>Valor</small>
            <Field
              name="valor"
              className="form-control"
              component={MoneyField}
              placeholder="Ex.: 1.000,00"
            />
            <ErrorMessage
              name="valor"
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>

        <Col md="3">
          <Form.Group controlId="formaPagamento">
            <small>Vencimento</small>
            <Field
              name="vencimento_intermediarias"
              className="form-control"
              as={MaskedInput}
              mask={MASK_DATE}
              placeholder="Ex.: 10/10/2023"
            />
            <ErrorMessage
              name="vencimento_intermediarias"
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <div className="d-flex justify-content-between">
            <div className="text-success">
              {valoresCalculados && (
                <>
                  <small className="d-block">Valor descontado</small>
                  <strong>{formatarMonetario(totalValorPresente)}</strong>
                </>
              )}
            </div>
            <div className="text-warning">
              {valoresCalculados && (
                <>
                  <small className="d-block">Valor projetado</small>
                  <strong>{formatarMonetario(totalValorFuturo)}</strong>
                </>
              )}
            </div>

            <Button
              variant="outline-primary"
              onClick={handleCalcular}
              disabled={!isFormValid()}
            >
              Calcular
            </Button>
          </div>
        </Col>
      </Row>

      {valoresCalculados && valoresCalculados.length > 0 && (
        <>
          <Row className="mt-2">
            <Col className="my-auto">
              <strong>Resumo </strong>
              <PerfectScrollbar
                style={{ maxHeight: '200px' }}
                className="border"
              >
                <Table className="table table-striped table-sm table-bordeless">
                  <Thead>
                    <Tr>
                      <Th>Valor Presente</Th>
                      <Th>Valor Futuro</Th>
                      <Th>Vencimento</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {valoresCalculados.map((item, index) => (
                      <Tr key={index}>
                        <Td>{formatarMonetario(item.valor_presente)}</Td>
                        <Td>{formatarMonetario(item.valor_futuro)}</Td>
                        <Td>{item.vencimento}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </PerfectScrollbar>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex justify-content-end mt-2">
              <Button
                variant="primary"
                disabled={isDisabled}
                onClick={() => incluirParcelas(codigo)}
              >
                Inserir
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ParcelasIntermediarias;
