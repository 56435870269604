import React, { Component } from 'react';
import Header from '~/Components/Header/Header';
import { connect } from 'react-redux';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { default as Selector } from 'react-select';
import { FaSearch, FaPlus } from 'react-icons/fa';
import { Formik, Field } from 'formik';

const mapStateToProps = state => ({
    userLogged: state.auth.user,
});

class ConsultarDescontos extends Component {
    render() {
        return (
            <div>
                <Header title="Consultar descontos" />
                <div className="p-4"> 
                    <Formik
                        initialValues={{ perfil: '', prospecto: '', status: '', empreendimento: '', incluirDesativados: false }}
                        onSubmit={(values) => {
                            console.log(values);
                        }}
                    >
                        {({ handleSubmit, handleChange, values }) => (
                            <Form onSubmit={handleSubmit}>
                                <br />
                                <div>
                                    <Row className="align-items-end mb-3"> 
                                        <Col xs={12}>
                                            <div className="float-right">
                                                <Button
                                                    variant="success"
                                                    type="button"
                                                    className="btn-success"
                                                >
                                                    <FaPlus />
                                                    <span>&nbsp;Solicitar Desconto</span>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="align-items-end mb-3">
                                        <Col xs={12} sm={8} lg={6}>
                                            <Form.Group controlId="FormPerfil">
                                                <Form.Label>Perfil</Form.Label>
                                                <Selector
                                                    noOptionsMessage={() => 'Nenhum perfil encontrado'}
                                                    placeholder="Selecione o perfil"
                                                    name="perfil"
                                                    onChange={(option) => handleChange({ target: { name: 'perfil', value: option } })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={8} lg={6}>
                                            <Form.Group controlId="FormProspecto">
                                                <Form.Label>Prospecto</Form.Label>
                                                <Field
                                                    name="prospecto"
                                                    placeholder="Informe o prospecto"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={values.prospecto}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="align-items-end mb-3">
                                        <Col xs={12} sm={8} lg={6}>
                                            <Form.Group controlId="FormStatus">
                                                <Form.Label>Status</Form.Label>
                                                <Selector
                                                    noOptionsMessage={() => 'Nenhum status encontrado'}
                                                    placeholder="Selecione o status"
                                                    name="status"
                                                    onChange={(option) => handleChange({ target: { name: 'status', value: option } })}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} sm={8} lg={6}>
                                            <Form.Group controlId="FormEmpreendimento">
                                                <Form.Label>Empreendimento</Form.Label>
                                                <Field
                                                    name="empreendimento"
                                                    className="form-control"
                                                    placeholder="Informe o empreendimento"
                                                    onChange={handleChange}
                                                    value={values.empreendimento}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="align-items-end mb-3">
                                        <Col xs={12} sm={8} lg={6}>
                                            <Form.Group controlId="FormIncluirSolicitacoes">
                                                <div className="checkbox">
                                                    <label>
                                                        <Field
                                                            type="checkbox"
                                                            name="incluirSolicitacoes"
                                                            onChange={handleChange}
                                                        />
                                                        &nbsp;Exibir todas as solicitações
                                                    </label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-end mb-3">
                                        <Col xs={12}>
                                            <div className="text-center">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                >
                                                    <FaSearch />
                                                    <span>&nbsp;Filtrar Descontos</span>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ConsultarDescontos);
