/**
 * @typedef {Object} Imovel
 * @property {number} area - Área do imóvel.
 * @property {any} ativo_escadinha - Informação sobre o ativo escadinha.
 * @property {any} cadastro_departamento_de_agua - Dados do cadastro no departamento de água.
 * @property {any} cadastro_prefeitura - Dados do cadastro na prefeitura.
 * @property {number} codigo - Código único do imóvel.
 * @property {number} codigo_familia - Código da família do imóvel.
 * @property {number} codigo_qualidade - Código de qualidade do imóvel.
 * @property {any} codigo_solicitacao_desconto - Código de solicitação de desconto.
 * @property {string} descricao - Descrição do imóvel.
 * @property {any} descricao_status_desconto - Status do desconto aplicado.
 * @property {any} empreendimento - Informações do empreendimento.
 * @property {any} ficha_tecnica - Ficha técnica do imóvel.
 * @property {boolean} is_caucionado - Indicador de caução.
 * @property {any} link_prefeitura - Link de cadastro na prefeitura.
 * @property {string} lote - Número do lote.
 * @property {any} matricula - Matrícula do imóvel.
 * @property {string} memorial_descritivo - Memorial descritivo do imóvel.
 * @property {any} parcelas - Informações das parcelas.
 * @property {string} quadra - Número da quadra.
 * @property {number} status - Status do imóvel.
 * @property {number} status_ativo - Status de ativo.
 * @property {any} tabela_preco_qualidade - Tabela de preços conforme a qualidade.
 * @property {boolean} tem_documentacao_extra - Indicador de documentação extra.
 * @property {any} ticket - Informação de ticket do imóvel.
 * @property {number} tipologia - Tipologia do imóvel.
 * @property {number} valor_parcela - Valor da parcela.
 * @property {number} valor_sinal - Valor do sinal.
 * @property {number} valor_venda - Valor de venda.
 * @property {number} valor_vista - Valor à vista.
 */

/**
 * @typedef {Object} Familia
 * @property {number} codigo - Código único da família.
 * @property {string} descricao - Descrição da família.
 * @property {Imovel[]} imoveis - Lista de imóveis associados à família.
 */

import React, { useEffect, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { ReactComponent as Mapa } from '../../Images/Mapa/mapa_modificado.svg';
import { StatusImovel } from '~/constant';
import ModalReserva from './ModalReserva';
import { MdCancel, MdRefresh, MdZoomIn, MdZoomOut } from 'react-icons/md';
import { formatarMonetario } from '~/Utils/Helpers';
import { Col, Row } from 'react-bootstrap';

/**
 * @param {Object} props
 * @param {Familia[]} props.ativos - Array de objetos do tipo Familia.
 */
const MapaInterativo = ({ ativos, atualizarMapa, atualizarReservados }) => {
  const [zoomQuadra, setZoomQuadra] = useState(null);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [codigoAtivo, setCodigoAtivo] = useState(null);
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [zoomLote, setZoomLote] = useState(null);
  const [ativoSelecionado, setAtivoSelecionado] = useState(null);

  const handleElementClick = (zoomToElement, elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      zoomToElement(element);
    }
  };

  const handleReset = resetTransform => {
    resetTransform();
    atualizarMapa();
    setZoomQuadra(null);
    setZoomLote(null);
  };

  const handleReservar = (event, resetTransform) => {
    const targetId = event.target.id;

    setCodigoAtivo(targetId);
    setMostrarModal(true);
    const ativo = ativos.find(imovel => {
      return imovel.codigo == targetId;
    });
    setAtivoSelecionado(ativo);

    handleReset(resetTransform);
  };

  useEffect(() => {
    ativos.forEach(imovel => {
      const loteElement = document.getElementById(imovel.codigo);
      if (loteElement) {
        loteElement.classList.remove(
          'disponivelF1',
          'disponivelF2',
          'vendido',
          'reservado',
          'bloqueado'
        );

        // Aplica a classe correspondente ao status do imóvel
        if (
          imovel.status === StatusImovel.Vendido ||
          imovel.status === StatusImovel.PreVenda
        ) {
          loteElement.classList.add('vendido');
        } else if (imovel.status === StatusImovel.Reservado) {
          loteElement.classList.add('reservado');
        } else if (imovel.status === StatusImovel.Bloqueado) {
          loteElement.classList.add('bloqueado');
        } else if (imovel.status === StatusImovel.Disponivel) {
          if (imovel.codigo_familia === 11452) {
            loteElement.classList.add('disponivelF1');
          } else if (imovel.codigo_familia === 11453) {
            loteElement.classList.add('disponivelF2');
          }
        }
      }
    });
  }, [ativos]);

  useEffect(() => {
    ativos.forEach(imovel => {
      const loteElement = document.getElementById(imovel.codigo);
      if (loteElement) {
        loteElement.addEventListener('mouseenter', event =>
          showTooltip(event, imovel)
        );
        loteElement.addEventListener('mouseleave', hideTooltip);
      }
    });

    return () => {
      ativos.forEach(imovel => {
        const loteElement = document.getElementById(imovel.codigo);
        if (loteElement) {
          loteElement.removeEventListener('mouseenter', showTooltip);
          loteElement.removeEventListener('mouseleave', hideTooltip);
        }
      });
    };
  }, [ativos]);

  const showTooltip = (event, imovel) => {
    setTooltipInfo(imovel);
    setTooltipPosition({ x: event.pageX, y: event.pageY });
  };

  const hideTooltip = () => {
    setTooltipInfo(null);
  };
  return (
    <div className="" style={{ position: 'relative' }}>
      <TransformWrapper>
        {({ zoomIn, zoomOut, zoomToElement, resetTransform }) => (
          <div className="w-100 h-100">
            {/* Div para os botões centralizados no topo */}
            <div
              style={{
                position: 'fixed',
                top: '100px',
                right: '23%',
                flexDirection: 'column',
                transform: 'translateX(-50%)',
                zIndex: 1000,
                display: 'flex',
                gap: '10px',
              }}
            >
              <Col>
                <Row className="mb-3">
                  <button
                    className="btn btn-secondary ml-3"
                    onClick={() => handleReset(resetTransform)}
                    style={{
                      borderRadius: '8px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <MdCancel />
                  </button>
                  <button
                    className="btn btn-success ml-3"
                    onClick={() => {
                      window.location.reload();
                    }}
                    style={{
                      borderRadius: '8px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <MdRefresh></MdRefresh>
                  </button>
                </Row>
                <Row className="mt-2">
                  <button
                    className="btn btn-secondary ml-3"
                    onClick={() => zoomIn()}
                    style={{
                      borderRadius: '8px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <MdZoomIn />
                  </button>
                  <button
                    className="btn btn-secondary ml-3"
                    onClick={() => {
                      atualizarMapa();
                      zoomOut();
                    }}
                    style={{
                      borderRadius: '8px',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <MdZoomOut />
                  </button>
                </Row>
              </Col>
            </div>
            <TransformComponent>
              <Mapa
                className="mapa-svg"
                style={{ background: '#353535' }}
                onClick={event => {
                  const targetId = event.target.id;
                  const parentElement = event.target.parentElement.id;
                  const isLote = event.target.closest('#LOTES');
                  if (isLote) {
                    if (parentElement && !zoomQuadra && !zoomLote) {
                      handleElementClick(zoomToElement, parentElement);
                      setZoomQuadra(parentElement);
                    } else if (targetId) {
                      handleReservar(event, resetTransform);
                    }
                  }
                }}
              />
              {ativoSelecionado && (
                <>
                  {' '}
                  <ModalReserva
                    mostrarModal={mostrarModal}
                    setMostrarModal={setMostrarModal}
                    codigoAtivo={codigoAtivo}
                    ativo={ativoSelecionado}
                    atualizarReservados={atualizarReservados}
                    atualizarMapa={atualizarMapa}
                  />
                </>
              )}
            </TransformComponent>
          </div>
        )}
      </TransformWrapper>

      {tooltipInfo && (
        <div
          style={{
            position: 'absolute',
            top: tooltipPosition.y - 50,
            left: tooltipPosition.x - 50,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            pointerEvents: 'none',
            zIndex: 1000,
            width: '300px',
            padding: '5px',
            display: 'flex',
            gap: '10px',
          }}
        >
          {/* Coluna 1 */}
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <div style={{ fontSize: '12px', color: '#333' }}>
              Fase:
              <strong>
                {' '}
                {tooltipInfo.codigo_familia == 11452 ? '1' : '2'}
              </strong>
            </div>

            <div style={{ fontSize: '12px', color: '#333' }}>
              Área:<strong> {tooltipInfo.area} m²</strong>
            </div>
            <div style={{ fontSize: '12px', color: '#333' }}>
              VV:<strong> {formatarMonetario(tooltipInfo.valor_vista)}</strong>
            </div>
            <div style={{ fontSize: '12px', color: '#333' }}>
              Entrada:
              <strong> {formatarMonetario(tooltipInfo.valor_sinal)}</strong>
            </div>
            {tooltipInfo.valor_inicial_parcela_programada && (
              <div style={{ fontSize: '12px', color: '#333' }}>
                Programadas:
                <strong>
                  {' '}
                  {formatarMonetario(
                    tooltipInfo.valor_inicial_parcela_programada
                  )}
                </strong>
              </div>
            )}
          </div>

          {/* Coluna 2 */}
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <div style={{ fontSize: '12px', color: '#333' }}>
              Quadra:<strong> {tooltipInfo.quadra}</strong> Lote:
              <strong> {tooltipInfo.lote}</strong>
            </div>
            {tooltipInfo.mesa && (
              <div style={{ fontSize: '12px', color: '#333' }}>
                Mesa: <strong>{tooltipInfo.mesa || 'lorem ipsun'}</strong>
              </div>
            )}
            {tooltipInfo.data_validade && (
              <div style={{ fontSize: '12px', color: '#333' }}>
                Validade:
                <strong>
                  {' '}
                  {`${new Date(tooltipInfo.data_validade)
                    .getDate()
                    .toString()
                    .padStart(2, '0')}/${(
                    new Date(tooltipInfo.data_validade).getMonth() + 1
                  )
                    .toString()
                    .padStart(2, '0')} ${new Date(tooltipInfo.data_validade)
                    .getHours()
                    .toString()
                    .padStart(2, '0')}:${new Date(tooltipInfo.data_validade)
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}`}
                </strong>
              </div>
            )}
            {tooltipInfo.data_reserva && (
              <div style={{ fontSize: '12px', color: '#333' }}>
                Reserva:
                <strong>
                  {' '}
                  {`${new Date(tooltipInfo.data_reserva)
                    .getDate()
                    .toString()
                    .padStart(2, '0')}/${(
                    new Date(tooltipInfo.data_reserva).getMonth() + 1
                  )
                    .toString()
                    .padStart(2, '0')} ${new Date(tooltipInfo.data_reserva)
                    .getHours()
                    .toString()
                    .padStart(2, '0')}:${new Date(tooltipInfo.data_reserva)
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}`}
                </strong>
              </div>
            )}
            {tooltipInfo.nome_corretor && (
              <div style={{ fontSize: '12px', color: '#333' }}>
                Corretor:<strong> {tooltipInfo.nome_corretor}</strong>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MapaInterativo;
