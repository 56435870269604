import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import {
  criarProspecto,
  importarProspectoSuaHouse,
} from '~/Store/Prospecto/actions';
import { Button, Modal, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _size from 'lodash/size';
import { FaSpinner } from 'react-icons/fa';
import history from '../../Config/history';
import { default as Selector } from 'react-select';
import { default as auth } from '../../Utils/AuthStorage';
import {
  TIPO_PESSOA,
  MASK_CNPJ,
  MASK_CPF,
} from '../../constant';
import * as helpers from '../../Utils/Helpers';
import SelectGerenteCorretor from '../GerentesCorretores/SelectGerenteCorretor';
import ConsultaCPF from './ProspectoDetalhes/ConsultaCPF';
import ConsultaEmail from './ProspectoDetalhes/ConsultaEmail';

const ModalAdicionarProspecto = ({
  location,
  empreendimentos,
  gerentesCorretores,
  criandoProspecto,
  criarProspecto,
  importarProspectoSuaHouse,
  erroCriandoProspecto,
  quandoCriarProspecto,
  liberarCadastroProspecto,
  configuracoesLancamento,
}) => {
  let params = new URLSearchParams(location.search);
  const isAdm = auth.isADM();
  const isGerente = auth.isGerente();
  const isCorretor = !isAdm && !isGerente;

  let initialValues = {
    tipo_pessoa: '0',
    nome: '',
    nome_social: '',
    email: '',
    tel_celular: '',
    tel_residencial: '',
    tel_comercial: '',
    codigo_familia: '',
    codigo_corretor: '',
    codigo_gerente: '',
    cpf: '',
    codigo_nacionalidade: 1,
    importacaoCRM: !isAdm && !liberarCadastroProspecto,
    crm_id: '',
  };

  const codigoUsuario = auth.getUser().usuario;
  if (isCorretor && gerentesCorretores.listaGerentes.length > 1) {
    initialValues.codigo_corretor = codigoUsuario;
    initialValues.codigo_gerente =
      gerentesCorretores.corretoresById[codigoUsuario].codigo_gerente;
  } else if (isGerente) {
    initialValues.codigo_gerente = codigoUsuario;
  }

  const validationSchema = Yup.object().shape({
    tipo_pessoa: Yup.number().when('importacaoCRM', {
      is: false,
      then: Yup.number().required('Selecione o tipo de pessoa'),
    }),
    nome: Yup.string().when('importacaoCRM', {
      is: false,
      then: Yup.string().required('Informe o nome'),
    }),
    tel_celular: Yup.string().when('importacaoCRM', {
      is: false,
      then: Yup.string(),
    }),
    crm_id: Yup.string().when('importacaoCRM', {
      is: true,
      then: Yup.string().required('Informe o ID do Lead no CRM'),
    }),
  });

  const validate = values => {
    let errors = {};
    if (!values.importacaoCRM && !values.email && !values.tel_celular) {
      errors.email = 'Informe ao menos um contato';
      errors.tel_celular = 'Informe ao meno um contato';
    }

    return errors;
  };

  const optionsEmpreendimento = empreendimentos.sort.map(item => {
    return {
      value: item.codigo,
      label: item.descricao,
    };
  });

  const [isDisabledLancamento, setIsDisabledLancamento] = useState(false);
  const [administradoresLancamento, setAdministradoresLancamento] = useState(
    false
  );
  useEffect(() => {
    if (!configuracoesLancamento) return;
    setAdministradoresLancamento(
      configuracoesLancamento.administradores.some(user => {
        return user.codigo_usuario === auth.getUser().usuario;
      })
    );
  }, [configuracoesLancamento]);

  const buildValueEmpreendimento = CodigoFamilia => {
    return CodigoFamilia
      ? {
          value: parseInt(empreendimentos.byId[CodigoFamilia].codigo),
          label: empreendimentos.byId[CodigoFamilia].descricao,
        }
      : { value: '', label: 'Selecione um empreendimento' };
  };

  const salvarProspecto = async values => {
    let codigo = 0;
    if (values.importacaoCRM && values.crm_id) {
      console.log(values);
      history.push(`/crm/importar?atendimentoIdGuid=${values.crm_id}`);
    } else {
      ({ codigo } = await criarProspecto(values));
    }
    if (quandoCriarProspecto) {
      quandoCriarProspecto(codigo);
    }
  };

  if (!params.get('novo')) {
    return null;
  }

  return (
    <Formik
      enableReinitialize={true}
      validate={validate}
      initialValues={initialValues}
      onSubmit={salvarProspecto}
      validationSchema={validationSchema}
    >
      {({
        setFieldValue,
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        submitCount,
        handleSubmit,
        setErrors,
        setTouched,
      }) => {
        return (
          <Modal show={true} onHide={() => history.push({ search: '' })}>
            <Modal.Header>
              <Modal.Title>
                {values.importacaoCRM ? 'Importar Lead do CRM' : 'Novo Cliente'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                {!values.importacaoCRM && (
                  <>
                    <Form.Group controlId="prospecto.codigo_familia">
                      <Form.Label srOnly>
                        Selecione um empreendimento
                      </Form.Label>
                      <Selector
                        defaultValue={
                          configuracoesLancamento
                            ? optionsEmpreendimento.find(
                                option =>
                                  option.value ===
                                  configuracoesLancamento.empreendimento
                              )
                            : null
                        }
                        noOptionsMessage={() =>
                          'Nenhum empreendimento encontrado'
                        }
                        placeholder="Pesquisar empreendimentos..."
                        options={optionsEmpreendimento}
                        onChange={option => {
                          if (
                            configuracoesLancamento &&
                            option.value ===
                              configuracoesLancamento.empreendimento &&
                            !administradoresLancamento
                          ) {
                            setFieldValue('codigo_corretor', 1078);
                            setFieldValue('codigo_gerente', 1077);
                            setIsDisabledLancamento(true);
                          } else {
                            setIsDisabledLancamento(false);
                          }
                          setFieldValue('codigo_familia', option.value);
                        }}
                        
                        disabled={criandoProspecto}
                      />
                    </Form.Group>

                    {values.codigo_familia && (
                      <SelectGerenteCorretor
                        gerente={values.codigo_gerente}
                        corretor={values.codigo_corretor}
                        onChangeCorretor={corretor =>
                          setFieldValue('codigo_corretor', corretor)
                        }
                        onChangeGerente={gerente =>
                          setFieldValue('codigo_gerente', gerente)
                        }
                        administradoresLancamento={isDisabledLancamento}
                      />
                    )}
                  </>
                )}
                {!values.importacaoCRM &&
                  values.codigo_familia &&
                  values.codigo_gerente &&
                  values.codigo_corretor && (
                    <React.Fragment>
                      <Form.Group controlId="values.tipo_pessoa">
                        <Form.Label>Tipo do Cadastro</Form.Label>
                        <Form.Control
                          as="select"
                          name="tipo_pessoa"
                          onChange={handleChange}
                          value={values.tipo_pessoa}
                          onBlur={handleBlur}
                          isInvalid={errors.tipo_pessoa && touched.tipo_pessoa}
                          disabled={criandoProspecto}
                        >
                          {TIPO_PESSOA.map((item, index) => (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.tipo_pessoa}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="values.cpf">
                        <Form.Label>
                          {values.tipo_pessoa === '0' ? 'CPF' : 'CNPJ'}
                        </Form.Label>
                        <ConsultaCPF
                          onChangeCPF={handleChange}
                          ignore={false}
                          values={values}
                        >
                          {({ onChangeCPF, consultandoCPF }) => (
                            <>
                              <MaskedInput
                                type="tel"
                                name="cpf"
                                className={`form-control ${errors.cpf &&
                                  touched.cpf &&
                                  'is-invalid'}`}
                                onChange={onChangeCPF}
                                onBlur={handleBlur}
                                value={helpers.format.cpf(values.cpf)}
                                placeholder={
                                  values.tipo_pessoa === '0'
                                    ? 'Ex.: 000.000.000-00'
                                    : 'Ex.: 00.000.000/0000-00'
                                }
                                mask={
                                  values.tipo_pessoa === '0'
                                    ? MASK_CPF
                                    : MASK_CNPJ
                                }
                                disabled={criandoProspecto || consultandoCPF}
                              />
                            </>
                          )}
                        </ConsultaCPF>
                        {errors.cpf && touched.cpf && (
                          <small className="form-text text-danger">
                            {errors.cpf}
                          </small>
                        )}
                      </Form.Group>
                      <Form.Group controlId="values.nome">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                          type="text"
                          name="nome"
                          maxLength="100"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nome}
                          isInvalid={errors.nome && touched.nome}
                          placeholder="Nome do cliente"
                          disabled={criandoProspecto}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.nome}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="values.nome_social">
                        <Form.Label>Nome Social</Form.Label>
                        <Form.Control
                          type="text"
                          name="nome_social"
                          maxLength="60"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.nome_social}
                          placeholder="Nome social do cliente"
                          disabled={criandoProspecto}
                        />
                      </Form.Group>
                      <Form.Group controlId="values.email">
                        <Form.Label>Email</Form.Label>
                        <ConsultaEmail
                          onChangeEmail={handleChange}
                          ignore={false}
                          values={values}
                        >
                          {({ onChangeEmail, consultandoEmail }) => (
                            <Form.Control
                              type="text"
                              name="email"
                              maxLength="60"
                              onChange={onChangeEmail}
                              onBlur={handleBlur}
                              value={values.email}
                              isInvalid={errors.email && touched.email}
                              placeholder="email@dominio.com.br"
                              disabled={criandoProspecto || consultandoEmail}
                            />
                          )}
                        </ConsultaEmail>

                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="values.tel_celular">
                        <Form.Label>Celular</Form.Label>
                        <MaskedInput
                          type="cel"
                          className={`form-control ${errors.tel_celular &&
                            touched.tel_celular &&
                            'is-invalid'}`}
                          name="tel_celular"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.tel_celular}
                          mask={helpers.mask.celular}
                          placeholder="(00) 00000-0000"
                          disabled={criandoProspecto}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.tel_celular}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="values.tel_residencial">
                        <Form.Label>Telefone residencial</Form.Label>
                        <MaskedInput
                          type="cel"
                          className={`form-control ${errors.tel_residencial &&
                            touched.tel_residencial &&
                            'is-invalid'}`}
                          name="tel_residencial"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.tel_residencial}
                          mask={helpers.mask.phone}
                          placeholder="(00) 0000-0000"
                          disabled={criandoProspecto}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.tel_residencial}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="values.tel_comercial">
                        <Form.Label>Telefone comercial</Form.Label>
                        <MaskedInput
                          type="tel"
                          className={`form-control ${errors.tel_comercial &&
                            touched.tel_comercial &&
                            'is-invalid'}`}
                          name="tel_comercial"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.tel_comercial}
                          mask={helpers.mask.phone}
                          placeholder="(00) 0000-0000"
                          disabled={criandoProspecto}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.tel_comercial}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </React.Fragment>
                  )}

                {values.importacaoCRM && (
                  <React.Fragment>
                    <Form.Group controlId="values.nome">
                      <Form.Label>Código Lead CRM</Form.Label>
                      <Form.Control
                        type="text"
                        name="crm_id"
                        maxLength="100"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.crm_id}
                        isInvalid={errors.crm_id && touched.crm_id}
                        placeholder="77"
                        disabled={criandoProspecto}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.crm_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </React.Fragment>
                )}
                {submitCount > 0 && _size(errors) > 0 && _size(touched) > 0 && (
                  <div className="FormDadosPessoais__error text-danger">
                    Preenchas os campos corretamente antes de salvar
                  </div>
                )}
                {erroCriandoProspecto && (
                  <div className="FormDadosPessoais__error text-danger">
                    {erroCriandoProspecto}
                  </div>
                )}
                <div className="d-flex d-sm-block text-right justify-content-between">
                  <Button
                    variant="link"
                    className="text-uppercase px-4 d-block d-sm-inline-block"
                    onClick={() => history.push({ search: '' })}
                    disabled={criandoProspecto}
                  >
                    Cancelar
                  </Button>
                  {!isGerente && (
                    <Button
                      variant="primary"
                      type="submit"
                      className="text-uppercase px-4 d-block d-sm-inline-block"
                      disabled={criandoProspecto}
                    >
                      {criandoProspecto ? (
                        <span>
                          <FaSpinner /> Salvando...
                        </span>
                      ) : (
                        'Prosseguir'
                      )}
                    </Button>
                  )}
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      criarProspecto,
      importarProspectoSuaHouse,
    },
    dispatch
  );

const mapStateToProps = state => ({
  empreendimentos: state.empreendimentos,
  gerentesCorretores: state.corretor,
  criandoProspecto: state.prospecto.criandoProspecto,
  erroCriandoProspecto: state.prospecto.erroCriandoProspecto,
  liberarCadastroProspecto: state.options.liberarCadastroProspecto,
  configuracoesLancamento: state.options.configuracoesLancamento,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalAdicionarProspecto)
);
