import { combineReducers } from 'redux';
import {
  FETCH_CITIES_SUCCESS,
  FETCH_OCCUPATIONS_SUCCESS,
  FETCH_NATIONALITIES_SUCCESS,
  FETCH_PDV_SUCCESS,
  FETCH_MOTIVOS_DESCARTE_SUCCESS,
  CARREGAR_PESQUISA_SUCESSO,
  CARREGANDO_PESQUISA,
  CARREGAR_PESQUISA_ERRO,
  FETCH_OPTIONS_SUCCESS,
  FETCH_USUARIOS_AUTORIZADORES_VENDA_SUCCESS,
  FETCHING_USUARIOS_AUTORIZADORES_VENDA,
} from '~/actionTypes';
import { capitalize } from '~/Utils/Helpers';
import * as constants from '~/constant';

const profissoes = (state = [], action) => {
  switch (action.type) {
    case FETCH_OCCUPATIONS_SUCCESS:
      return action.payload.map(item => ({
        ...item,
        descricao: capitalize(item.descricao),
      }));
    default:
      return state;
  }
};

const profissoesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_OCCUPATIONS_SUCCESS:
      return action.payload.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.codigo]: curr,
        };
      }, {});
    default:
      return state;
  }
};

const cidades = (state = [], action) => {
  switch (action.type) {
    case FETCH_CITIES_SUCCESS:
      return action.payload.map(item => ({
        ...item,
        descricao: capitalize(item.descricao),
      }));
    default:
      return state;
  }
};

const cidadesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CITIES_SUCCESS:
      return action.payload.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.codigo]: curr,
        };
      }, {});
    default:
      return state;
  }
};

const nacionalidades = (state = [], action) => {
  switch (action.type) {
    case FETCH_NATIONALITIES_SUCCESS:
      return action.payload.map(item => ({
        ...item,
        descricao: capitalize(item.descricao),
      }));
    default:
      return state;
  }
};

const liberarCadastroProspecto = (state = [], action) => {
  switch (action.type) {
    case FETCH_OPTIONS_SUCCESS:
      return action.payload.libera_cadastro_prospecto;
    default:
      return state;
  }
};

const nacionalidadesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_NATIONALITIES_SUCCESS:
      return action.payload.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.codigo]: curr,
        };
      }, {});
    default:
      return state;
  }
};

const pdv = (state = [], action) => {
  switch (action.type) {
    case FETCH_PDV_SUCCESS:
      return action.payload.map(item => ({
        ...item,
        descricao: capitalize(item.descricao),
      }));
    default:
      return state;
  }
};

const pdvById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PDV_SUCCESS:
      return action.payload.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.codigo]: curr,
        };
      }, {});
    default:
      return state;
  }
};

const motivosDescarte = (state = [], action) => {
  switch (action.type) {
    case FETCH_MOTIVOS_DESCARTE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const montarGruposPesquisa = pesquisa => {
  let grupos = [];

  pesquisa.forEach(item => {
    if (constants.PERGUNTAS_PARENTES[item.Id]) {
      item = {
        ...item,
        Parent: constants.PERGUNTAS_PARENTES[item.Id].Parent,
      };
    }

    let grupo = {
      Id: null,
      descricao: null,
      Legenda: null,
      Perguntas: [],
      GrupoPergunta: item.GrupoPergunta,
    };
    if (!item.Grupo) {
      delete item.Grupo;
      grupo.Perguntas.push(item);
      grupos.push(grupo);
    } else {
      let grupo = grupos.find(item => grupo.Id === item.Grupo.Id);
      if (grupo) {
        delete item.Grupo;
        grupo.Perguntas.push(item);
      } else {
        grupo = {
          ...grupo,
          ...item.Grupo,
        };

        delete item.Grupo;
        grupo.Perguntas.push(item);

        grupos.push(grupo);
      }
    }
  });

  return grupos;
};

const pesquisa = (state = [], action) => {
  switch (action.type) {
    case CARREGAR_PESQUISA_SUCESSO:
      return montarGruposPesquisa(action.payload);
    default:
      return state;
  }
};

const pesquisaByCodigo = (state = [], action) => {
  switch (action.type) {
    case CARREGAR_PESQUISA_SUCESSO:
      return action.payload.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.codigo]: curr,
        };
      }, {});
    default:
      return state;
  }
};

const carregandoPesquisa = (state = false, action) => {
  switch (action.type) {
    case CARREGANDO_PESQUISA:
      return true;
    case CARREGAR_PESQUISA_SUCESSO:
    case CARREGAR_PESQUISA_ERRO:
      return false;
    default:
      return state;
  }
};

const optionsCarregado = (state = false, action) => {
  switch (action.type) {
    case FETCH_OPTIONS_SUCCESS:
      return true;
    default:
      return state;
  }
};
const configuracoesLancamento = (state = false, action) => {
  switch (action.type) {
    case FETCH_OPTIONS_SUCCESS:
      return action.payload.configuracao_lancamento;
    default:
      return state;
  }
};

const usuariosAutorizadoresVenda = (state = [], action) => {
  switch (action.type) {
    case FETCH_USUARIOS_AUTORIZADORES_VENDA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const buscandoUsuariosAutorizadoresVenda = (state = false, action) => {
  switch (action.type) {
    case FETCHING_USUARIOS_AUTORIZADORES_VENDA:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  pesquisa,
  pesquisaByCodigo,
  carregandoPesquisa,
  profissoes,
  profissoesById,
  nacionalidades,
  nacionalidadesById,
  pdv,
  pdvById,
  cidades,
  cidadesById,
  motivosDescarte,
  optionsCarregado,
  usuariosAutorizadoresVenda,
  buscandoUsuariosAutorizadoresVenda,
  liberarCadastroProspecto,
  configuracoesLancamento,
});
