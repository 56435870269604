//QUEM RECEBE O OBJETO NA AÇÃO DO ADD PARCELAS
export const adicionarParcelaFinanciamento = (
  codigoAtivo,
  valorPresente,
  valor,
  vencimento,
  tipo,
  parcelas,
  numeroDaParcela,
  tipoParcelaEscadinha,
  tipoBalao,
  taxa,
  tipoIntermediaria
) => ({
  type: '@vendas/ADICIONA_PARCELA_FINANCIAMENTO',
  payload: {
    codigo: codigoAtivo,
    valorPresente,
    valor,
    vencimento,
    tipo,
    parcelas,
    numeroDaParcela,
    tipoParcelaEscadinha,
    tipoBalao,
    taxa,
    tipoIntermediaria,
  },
});

export const removerParcelaFinanciamento = (codigoAtivo, parcela) => ({
  type: '@vendas/REMOVE_PARCELA_FINANCIAMENTO',
  payload: {
    codigo: codigoAtivo,
    parcela,
  },
});

export const removerTodasParcelasFinanciamento = codigoAtivo => ({
  type: '@vendas/REMOVE_TODAS_PARCELAS_FINANCIAMENTO',
  payload: {
    codigo: codigoAtivo,
  },
});

export const atualizarParcelaFinanciamento = (
  codigoAtivo,
  parcela,
  valor,
  vencimento
) => ({
  type: '@vendas/ATUALIZA_PARCELA_FINANCIAMENTO',
  payload: {
    codigo: codigoAtivo,
    parcela,
    valor,
    vencimento,
  },
});
