/**
 * @typedef {import('../CondicoesEspeciais/ModalCondicoesEspeciais').CondicoesEspeciais} CondicoesEspeciais
 */
import React, { Fragment } from 'react';
import { Alert } from 'react-bootstrap';
import { MdKeyboardArrowRight, MdWarning } from 'react-icons/md';
import defaultLogo from '~/Images/logo.svg';
import { formatarMonetario } from '~/Utils/Helpers.js';
import { Container } from './styles';

/**
 * @param {{condicoesEspeciais: CondicoesEspeciais}}
 */
export default function ResumoVenda({
  empreendimento,
  tabelaSelecionada,
  totalVenda,
  totalTitulosSinal,
  ativosSelecionados,
  ativosPorCodigo,
  ativosDaTabelaSelecionadaPorCodigo,
  permissaoAvancar,
  quandoAvancar,
  prospecto,
  etapaAtual,
  etapas,
  listaMotivosAutorizacao,
  condicoesEspeciais,
}) {
  const etapaFinal = etapas[etapas.length - 1];
  const {
    descricao,
    financiamento,
    isIsencao,
    saldoCorrecao,
    temCondicao,
    valorLote,
  } = condicoesEspeciais;  
  return (
    <Container>
      <ul className="list-group mb-3">
        <li className="list-group-item d-flex justify-content-between">
          <div>
            <small>Empreendimento</small>
            <strong className="text-dark d-block">
              {empreendimento.descricao}
            </strong>
          </div>
          <div className="img-resumo-imobiliaria">
            <img
              src={empreendimento.logo ? empreendimento.logo : defaultLogo}
              title={empreendimento.descricao}
              alt={empreendimento.descricao}
            />
          </div>
        </li>

        {prospecto && (
          <li className="list-group-item d-flex justify-content-between">
            <div>
              <small>Cliente</small>
              <strong className="text-dark d-block">{prospecto.nome}</strong>
            </div>
          </li>
        )}

        {tabelaSelecionada && (
          <>
            <li className="list-group-item d-flex justify-content-between">
              <div>
                <small>Tabela</small>
                <strong className="text-dark d-block">
                  {tabelaSelecionada.descricao}
                </strong>
              </div>
            </li>
            <li className="list-group-item d-flex justify-content-between bg-light py-1">
              <span>Lotes</span>
              <span className="badge badge-dark badge-pill">
                {ativosSelecionados ? ativosSelecionados.length : 0}
              </span>
            </li>
          </>
        )}

        {ativosSelecionados.map((item, index) => (
          <Fragment key={index}>
            <li className="list-group-item">
              <span className="d-block text-very-small">#{item}</span>
              <div className="d-flex justify-content-between">
                <div>
                  <span>
                    Quadra{' '}
                    <strong className="text-dark">
                      {ativosPorCodigo[item].quadra}
                    </strong>{' '}
                    / Lote{' '}
                    <strong className="text-dark">
                      {ativosPorCodigo[item].lote}
                    </strong>
                  </span>
                  {tabelaSelecionada && (
                    <small className="d-block">
                      Entrada{' '}
                      <strong className="text-dark">
                        {formatarMonetario(
                          ativosDaTabelaSelecionadaPorCodigo[item].valor_sinal
                        )}
                      </strong>
                    </small>
                  )}
                </div>
                {tabelaSelecionada && (
                  <div>
                    <span className="my-auto">
                      <strong className="text-dark">
                        {formatarMonetario(
                          ativosDaTabelaSelecionadaPorCodigo[item].valor_venda
                        )}
                      </strong>
                    </span>
                    {empreendimento.plano_selecionado && (
                      <small className="d-block">
                        {`${empreendimento.plano_selecionado}x R$ `}
                        <strong className="text-dark">
                          {formatarMonetario(
                            ativosDaTabelaSelecionadaPorCodigo[item].valor_sinal
                          )}
                        </strong>
                      </small>
                    )}
                  </div>
                )}
              </div>
            </li>
            {tabelaSelecionada &&
              ativosDaTabelaSelecionadaPorCodigo[item].desconto && (
                <>
                  {!!ativosDaTabelaSelecionadaPorCodigo[item].desconto
                    .valor_venda && (
                    <>
                      {ativosDaTabelaSelecionadaPorCodigo[item].desconto
                        .valor_venda > 0 ? (
                        <li className="list-group-item d-flex justify-content-between bg-light">
                          <div className="text-success">
                            <h6 className="my-0">Desconto</h6>
                            <small>VALOR DA VENDA</small>
                          </div>
                          <span className="my-auto text-success">
                            -
                            <strong>
                              {formatarMonetario(
                                ativosDaTabelaSelecionadaPorCodigo[item]
                                  .desconto.valor_venda
                              )}
                            </strong>
                          </span>
                        </li>
                      ) : (
                        <li className="list-group-item d-flex justify-content-between bg-light">
                          <div className="text-warning">
                            <h6 className="my-0">Acréscimo</h6>
                            <small>VALOR DA VENDA</small>
                          </div>
                          <span className="my-auto text-warning">
                            <strong>
                              {formatarMonetario(
                                Math.abs(
                                  ativosDaTabelaSelecionadaPorCodigo[item]
                                    .desconto.valor_venda
                                )
                              )}
                            </strong>
                          </span>
                        </li>
                      )}
                    </>
                  )}
                  {!!ativosDaTabelaSelecionadaPorCodigo[item].desconto
                    .valor_sinal && (
                    <li className="list-group-item d-flex justify-content-between bg-light">
                      <div className="text-success">
                        <h6 className="my-0">Desconto</h6>
                        <small>SINAL/ENTRADA</small>
                      </div>
                      <span className="my-auto text-success">
                        -
                        <strong>
                          {formatarMonetario(
                            ativosDaTabelaSelecionadaPorCodigo[item].desconto
                              .valor_sinal
                          )}
                        </strong>
                      </span>
                    </li>
                  )}
                </>
              )}
          </Fragment>
        ))}
        {tabelaSelecionada && (
          <>
            {totalVenda > 0 && (
              <li className="list-group-item d-flex justify-content-between">
                <span>Valor da venda</span>
                <span className="my-auto">
                  <strong className="text-dark">
                    {formatarMonetario(totalVenda)}
                  </strong>
                </span>
              </li>
            )}
            {totalTitulosSinal > 0 && (
              <li className="list-group-item d-flex justify-content-between">
                <span>Entrada</span>
                <span className="my-auto">
                  <strong className="text-dark">
                    {formatarMonetario(totalTitulosSinal)}
                  </strong>
                </span>
              </li>
            )}
            {/* {totalFinanciamento > 0 && (
              <li className="list-group-item d-flex justify-content-between">
                <span>Financiamento</span>
                <span className="my-auto">
                  <strong className="text-dark">
                    {formatarMonetario(totalFinanciamento)}
                  </strong>
                </span>
              </li>
            )} */}
          </>
        )}
      </ul>

      {etapaAtual !== etapaFinal && (
        <>
          {!permissaoAvancar.permitir && (
            <small className="text-muted">{permissaoAvancar.mensagem}</small>
          )}
          <button
            className={`btn btn-${
              permissaoAvancar.permitir ? 'primary' : 'secondary'
            } btn-block text-uppercase justify-content-between d-flex align-items-center py-3 px-4 shadow-sm`}
            type="button"
            onClick={quandoAvancar}
            disabled={!permissaoAvancar.permitir}
          >
            Avançar <MdKeyboardArrowRight />
          </button>
        </>
      )}

      {etapaAtual !== etapas[0] && listaMotivosAutorizacao.length > 0 && (
        <Alert variant="warning" className="mt-3">
          <p className="text">
            <MdWarning className="mr-1" /> Esta venda requer autorização pelos
            seguintes motivos:
          </p>
          <ol className="pl-3">
            {listaMotivosAutorizacao.map((motivo, index) => (
              <li key={index}>{motivo.descricao}</li>
            ))}
          </ol>
        </Alert>
      )}

      {condicoesEspeciais && etapaAtual !== etapas[0] ? (
        <>
          <Alert variant="primary" className="mt-2">
            <h5>Condições especiais</h5>
            <div>
              <small>
                Taxa de isenção:{' '}
                <strong>{condicoesEspeciais.isIsencao ? 'Sim' : 'Não'}</strong>
              </small>
            </div>
            <div>
              <small>
                Descrição: <strong>{descricao}</strong>
              </small>
            </div>
            {financiamento && financiamento.isFinanciamento && (
              <>
                <hr />
                <div>
                  <small>
                    Avaliação bancária:{' '}
                    <strong>
                      {financiamento && financiamento.avalBanc
                        ? formatarMonetario(financiamento.avalBanc)
                        : 'R$ 0,00'}
                    </strong>
                  </small>
                </div>
                <div>
                  <small>
                    Prazo financiamento:{' '}
                    <strong>
                      {(financiamento && financiamento.prazoFinan) || '0'}
                    </strong>
                  </small>
                </div>
                <div>
                  <small>
                    Término construção:{' '}
                    <strong>
                      {(financiamento && financiamento.terminoConstrucao) ||
                        '0'}
                    </strong>
                  </small>
                </div>
              </>
            )}
            {saldoCorrecao && saldoCorrecao.isSaldoCorrecao && (
              <>
                <hr />
                <div>
                  <small>
                    Saldo correção:{' '}
                    <strong>
                      {saldoCorrecao && saldoCorrecao.saldoCorrecao
                        ? formatarMonetario(saldoCorrecao.saldoCorrecao)
                        : 'R$ 0,00'}
                    </strong>
                  </small>
                </div>
                <div>
                  <small>
                    Data saldo:{' '}
                    <strong>
                      {(saldoCorrecao && saldoCorrecao.dataSaldo) ||
                        'data não informada'}
                    </strong>
                  </small>
                </div>
                <div>
                  <small>
                    Índice de correção:{' '}
                    <strong>
                      {(saldoCorrecao && saldoCorrecao.indiceCorrecao) || '0'}
                    </strong>
                  </small>
                </div>
              </>
            )}
            {valorLote && valorLote.isValorLote && (
              <>
                <hr />
                <div>
                  <small>
                    Valor sugerido:{' '}
                    <strong>
                      {valorLote && valorLote.valorSugerido
                        ? formatarMonetario(valorLote.valorSugerido)
                        : 'R$ 0,00'}
                    </strong>
                  </small>
                </div>
                <div>
                  <small>
                    Porcentagem de desconto:{' '}
                    <strong>
                      {(valorLote && valorLote.porcentagemDesconto) || '0'}%
                    </strong>
                  </small>
                </div>
              </>
            )}
          </Alert>
        </>
      ) : (
        ''
      )}
    </Container>
  );
}
