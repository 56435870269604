import { combineReducers } from 'redux';

import sinal from './Sinal/reducer';
import financiamentos from './Financiamento/reducer';
import divisaoComissao from './DivisaoComissao/reducer';
import checkout from './Checkout/reducer';

const INITIAL_STATE = {
  tabelaSelecionada: null,
  codigoConstrutor: null,
  ativosSelecionados: [],
  empreendimento: null,
  ativosPorCodigo: {},
  tabelasPorCodigo: {},
  ativosDaTabelaSelecionadaPorCodigo: {},
  prospecto: null,
  cartoes: [],
  condicoesEspeciais: [],
  vendaInterna: null,
};

const carregandoPropostaInicial = (state = false, action) => {
  switch (action.type) {
    case '@vendas/CARREGANDO_PROPOSTA':
      return true;
    case '@vendas/ERRO_CARREGAR_PROPOSTA':
    case '@vendas/PROPOSTA_CARREGADA':
      return false;
    default:
      return state;
  }
};

const erroCarregamentoPropostaInicial = (state = null, action) => {
  switch (action.type) {
    case '@vendas/ERRO_CARREGAR_PROPOSTA':
      return action.payload;
    case '@vendas/CARREGANDO_PROPOSTA':
    case '@vendas/PROPOSTA_CARREGADA':
      return null;
    default:
      return state;
  }
};

const tabelaSelecionada = (state = INITIAL_STATE.tabelaSelecionada, action) => {
  switch (action.type) {
    case '@vendas/SELECIONA_TABELA_PRECO':
      return action.payload.codigoTabela;
    default:
      return state;
  }
};

const construtorSelecionado = (
  state = INITIAL_STATE.codigoConstrutor,
  action
) => {
  switch (action.type) {
    case '@vendas/SELECIONA_CONSTRUTOR':
      return action.payload.codigoConstrutor;
    default:
      return state;
  }
};

const ativosSelecionados = (
  state = INITIAL_STATE.ativosSelecionados,
  action
) => {
  switch (action.type) {
    case '@vendas/SELECIONA_ATIVO':
      const index = state.indexOf(action.payload.codigoAtivo);

      if (index > -1) {
        return [...state.slice(0, index), ...state.slice(index + 1)];
      } else {
        return [
          ...state.slice(0, index),
          action.payload.codigoAtivo,
          ...state.slice(index),
        ];
      }
    case '@vendas/SELECIONA_TABELA_PRECO':
      return [];
    default:
      return state;
  }
};

const tabelasPorCodigo = (state = INITIAL_STATE.tabelasPorCodigo, action) => {
  switch (action.type) {
    case '@vendas/PROPOSTA_CARREGADA':
      return action.payload.proposta.empreendimento.tabelas.reduce(
        (prev, curr) => {
          prev[curr.codigo] = curr;
          return prev;
        },
        {}
      );
    default:
      return state;
  }
};

const empreendimento = (state = INITIAL_STATE.empreendimento, action) => {
  switch (action.type) {
    case '@vendas/PROPOSTA_CARREGADA':
      return action.payload.proposta.empreendimento;
    default:
      return state;
  }
};

const ativosPorCodigo = (state = INITIAL_STATE.ativosPorCodigo, action) => {
  switch (action.type) {
    case '@vendas/PROPOSTA_CARREGADA':
      return action.payload.proposta.empreendimento.ativos.reduce(
        (prev, curr) => {
          prev[curr.codigo] = curr;
          return prev;
        },
        {}
      );
    default:
      return state;
  }
};

const ativosDaTabelaSelecionadaPorCodigo = (
  state = INITIAL_STATE.ativosDaTabelaSelecionadaPorCodigo,
  action
) => {
  switch (action.type) {
    case '@vendas/SELECIONA_TABELA_PRECO':
      return action.payload.ativos.reduce((prev, curr) => {
        prev[curr.codigo] = {
          ...curr,
          valor_venda_original: curr.valor_venda,
          valor_sinal_original: curr.valor_sinal,
        };
        return prev;
      }, {});
    case '@vendas/SALVAR_SINAL': {
      const {
        codigo,
        valor_sinal,
        valor_venda,
        desconto_sinal,
        desconto_valor_venda,
        aplicou_cheque_bonus_sinal,
        aplicou_cheque_bonus_valor_venda,
        codigo_solicitacao_desconto,
      } = action.payload;

      let desconto = {
        codigo_solicitacao_desconto,
        aplicou_cheque_bonus_sinal,
        aplicou_cheque_bonus_valor_venda,
      };

      desconto['valor_venda'] = desconto_valor_venda;
      desconto['valor_sinal'] = desconto_sinal;

      return {
        ...state,
        [codigo]: {
          ...state[codigo],
          valor_sinal,
          valor_venda,
          desconto,
        },
      };
    }

    default:
      return state;
  }
};

export const prospecto = (
  state = INITIAL_STATE.prospecto,
  { type, payload }
) => {
  switch (type) {
    case '@vendas/SET_PROSPECTO':
      return payload.prospecto;
    case 'FETCH_PROSPECT_DETAILS_SUCCESS':
    case 'UPDATE_PROSPECT_SUCCESS':
      return {
        ...state,
        ...payload,
      };
    case 'SELECIONA_EMPREENDIMENTO':
      const index = state.prospecto_empreendimento.findIndex(
        item => item.codigo_familia === Number(payload)
      );
      if (index < 0) return state;
      return {
        ...state,
        ...state.prospecto_empreendimento[index],
      };
    default:
      return state;
  }
};

export const condicoesEspeciais = (
  state = INITIAL_STATE.condicoesEspeciais,
  { type, payload }
) => {
  switch (type) {
    case '@vendas/SET_CONDICOES_ESPECIAIS':
      return payload.condicoesEspeciais;
    default:
      return state;
  }
};

export const vendaInterna = (
  state = INITIAL_STATE.vendaInterna,
  {type, payload}
) => {
  switch (type){
    case '@vendas/SET_VENDA_INTERNA':
      return payload.vendaInterna
    default:
        return state;
  }
}

export const isGravandoVenda = (state = false, action) => {
  switch (action.type) {
    case '@vendas/GRAVANDO_VENDA':
      return true;
    case '@vendas/GRAVAR_VENDA_SUCESSO':
    case '@vendas/GRAVAR_VENDA_ERRO':
      return false;
    default:
      return state;
  }
};

export const vendaGravada = (state = null, action) => {
  switch (action.type) {
    case '@vendas/CARREGANDO_PROPOSTA':
      return null;
    case '@vendas/GRAVAR_VENDA_SUCESSO':
      return action.payload;
    default:
      return state;
  }
};

export const erroGravacaoVenda = (state = '', action) => {
  switch (action.type) {
    case '@vendas/GRAVANDO_VENDA':
      return '';
    case '@vendas/GRAVAR_VENDA_ERRO':
      return action.payload;
    default:
      return state;
  }
};

export const codigoProspecto = (state = null, action) => {
  switch (action.type) {
    case '@vendas/PROPOSTA_CARREGADA':
      return action.payload.proposta.codigo_prospecto;
    default:
      return state;
  }
};

export const codigoFamilia = (state = null, action) => {
  switch (action.type) {
    case '@vendas/PROPOSTA_CARREGADA':
      return action.payload.proposta.empreendimento.codigo;
    default:
      return state;
  }
};

export const isExcluindoVenda = (state = false, action) => {
  switch (action.type) {
    case '@vendas/EXCLUINDO_VENDA':
      return true;
    case '@vendas/EXCLUIR_VENDA_SUCESSO':
    case '@vendas/EXCLUIR_VENDA_ERRO':
      return false;
    default:
      return state;
  }
};

export const erroExclusaoVenda = (state = '', action) => {
  switch (action.type) {
    case '@vendas/EXCLUINDO_VENDA':
      return '';
    case '@vendas/EXCLUIR_VENDA_ERRO':
      return action.payload;
    default:
      return state;
  }
};

export const vendaExcluida = (state = false, action) => {
  switch (action.type) {
    case '@vendas/CARREGANDO_PROPOSTA':
      return false;
    case '@vendas/EXCLUIR_VENDA_SUCESSO':
      return true;
    default:
      return state;
  }
};

export const cartoes = (state = null, action) => {
  switch (action.type) {
    case '@vendas/PROPOSTA_CARREGADA':
      return action.payload.proposta.cartoes;
    default:
      return state;
  }
};

export default combineReducers({
  carregandoPropostaInicial,
  erroCarregamentoPropostaInicial,
  prospecto,
  condicoesEspeciais,
  vendaInterna,
  empreendimento,
  tabelasPorCodigo,
  ativosPorCodigo,
  tabelaSelecionada,
  construtorSelecionado,
  ativosSelecionados,
  ativosDaTabelaSelecionadaPorCodigo,
  sinal,
  financiamentos,
  isGravandoVenda,
  vendaGravada,
  erroGravacaoVenda,
  divisaoComissao,
  codigoProspecto,
  codigoFamilia,
  isExcluindoVenda,
  erroExclusaoVenda,
  vendaExcluida,
  checkout,
  cartoes,
});
